.book-card {
    padding: 1rem;
    border: 1px solid #cebeb1 !important;
    border-radius: 10px;
    transition: .5s;
    background: #fff;
}

.book-card .image {
    overflow: hidden;
    border-radius: 8px;
}

.book-card img {
    /* border-radius: 10px; */
    transition: .5s;
    width: 100%;
}

.book-card .rupee {
    font-size: 1.2rem;
}

.book-card h4 {
    font-family: inherit;
    margin-bottom: 0;
}

.book-card p.mb-2 {
    font-size: .9rem;
}

.book-card>a {
    font-size: 1.4rem;
    margin-top: 10px;
    display: inline-block;
    text-transform: capitalize;
}

.book-card:hover {
    border-color: var(--theme-color-main)8e;
}

.image-book-card img {
    /* height: 312px; */
}

.rupees {
    font-size: 25px;
}

.book-card .content-book {
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.book-section {
    background: url(../../img/service-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
}

.book-image {
    border-radius: 10px;
    overflow: hidden;
    height: 600px !important;
    width: 100%;
    object-fit: cover;
    /* object-position: 0 -100px; */
}

.content-book-card {
    background: #fff;
}


.image-book-card img {
    border-radius: 10px 0 0 10px !important;
}

@media (max-width:500px) {
    .image-book-card img {
        height: auto !important;
    }

    .book-card {
        overflow: hidden;
    }

    .image-book-card img {
        border-radius: 10px 10px 0 0 !important;
    }

    .image-book-card .ant-skeleton-image {
        height: auto !important;
        aspect-ratio: 1/.7;
    }
}

@media (max-width: 992px) {
    .book-card .content-book {
        /* height: 100%; */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 10px;
    }
}