.thankyou-appo-page .col-lg-8{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.thankyou-appo-page .col-lg-8 img{
    width: 150px;
}
.thankyou-appo-page .col-lg-8 .booking-detail-table{
    text-align: left;
    border: 2px solid var(--theme-color-main);
    background: #f8f0fc;
    padding: 1rem;
    border-radius: 10px;
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: auto;
}
.thankyou-appo-page .col-lg-8 .booking-detail-table .bd-box{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--theme-color-main);
    padding-bottom: 10px;
    margin-bottom: 10px;
    gap: 1rem;
    font-weight: 500;

}
.thankyou-appo-page .col-lg-8 .booking-detail-table .bd-box p{
    margin: 0;
    text-align: right;
}
.thankyou-appo-page .col-lg-8 .booking-detail-table .bd-box:last-child{
    margin-bottom: 0;
    padding-bottom: 0;
    border: 0;
}
@media (max-width:568px) {
    .thankyou-appo-page .col-lg-8 .booking-detail-table .bd-box:last-child p{
        width: 68%;
    }
}