.about-page .page-title-area {
  background: url(../img/banners/aboutus-banner.jpg);
}

.inner-section .three-section-card {
  text-align: center;
}

.inner-section .three-section-card {
  border: none;
}

.inner-section .three-section-card-1,
.inner-section .three-section-card-3 {
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.inner-section .three-section-card-2,
.inner-section .three-section-card-5,
.inner-section .three-section-card-7 {
  border-bottom: 1px solid #e5e5e5;
}

.inner-section .three-section-card-4 {
  border-right: 1px solid #e5e5e5;
  border-bottom: none;
}

.inner-section .ts-icon img {
  width: 150px !important;
  height: 150px;
}

.media-page .media-banner {
  background: url("../img/banners/Media.jpg");
  background-position: top;
}

.media-card iframe {
  width: 100%;
  height: 350px;
  /* aspect-ratio: 1/1; */
}


@media (max-width: 568px) {
  .inner-section .three-section-card {
    border: none;
    border-bottom: 1px solid #e5e5e5;
    padding: 2rem 1rem;
  }

  .inner-section .three-section-card-4 {
    border-bottom: none;
  }

  .inner-section .three-section-card-5 {
    border: none;
    border-bottom: 1px solid #e5e5e5;
  }

  .inner-section .about-section .content-column {
    margin: 0;
  }

  .inner-section .about-section {
    margin-bottom: 20px;
    text-align: justify;
  }
}