.c-card {
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    /* padding: 1rem; */
    /* padding: 15px; */
    /* border: 3px solid rgba(0, 0, 0, 0.07); */
    /* border-radius: 50%; */
    transition: .5s;
    /* height: 100%; */
    text-align: center;
    /* aspect-ratio: 1/1; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.c-card .c-img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
    /* border-radius: 50%; */
}

.c-card img {
    /* border-radius: 50px; */
    transition: .5s;
    width: 100%;
}

.c-card>a {
    font-size: 1.4rem;
    margin-top: 10px;
    display: inline-block;
    text-transform: capitalize;
}

.c-card:hover {
    border-color: var(--theme-color-main)8e;
}

.c-card:hover img {
    transform: scale(1.1);
}

.c-content {
    font-weight: 400;
    font-size: 25px;
    margin-top: 10px;
    text-align: center;
}

.c-card .ant-skeleton {
    aspect-ratio: 1/1;
}

@media (max-width:568px) {
    .c-content {
        font-size: 1rem;
    }

    .c-card img {
        border-radius: 10px 10px 10px 10px !important;
    }
}