.profile-card {
  border: 1px solid #cebeb1;
  /* padding: 1rem; */
  border-radius: 5px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profile-card img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 2rem;
  /* margin-bottom: 1rem; */
}

:where(.css-dev-only-do-not-override-1hpnbz2).ant-btn {
  font-size: 14px;
  line-height: 1.5714285714285714;
  height: 36px !important;
  padding: 4px 15px;
  border-radius: 6px;
}

.profile-card .d-flex {
  padding: 1rem;
}

.order-card {
  border: 1px solid #cebeb1 !important;
  border-radius: 5px;
  margin: 0;
  padding: 1rem .2rem;
}

/* .profile-card .img p, */
.profile-card .content p {
  margin: 0;
  font-weight: bold;
}

.profile-card .img lable,
.profile-card .content lable {
  font-size: 0.8rem;
  color: #606060;
}

.profile-card .btns {
  background-color: var(--theme-color-main);
  /* padding: 1rem; */
  display: flex;
  justify-content: space-between;
}

.address {
  background: var(--theme-color-main);
  padding: 10px 15px;
  border-radius: 50px;
  color: #fff !important;
}



.profile-card .btns button {
  color: var(--white-color);
  background: transparent;
  border: none;
  padding: 10px 15px;
  text-align: center;
  width: 25%;
}

.profile-card .btns button:nth-child(2) {
  border-left: 1px solid var(--white-color);
  border-right: 1px solid var(--white-color);
  width: 50%;
}

.four-card {
  padding: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  height: 100%;
}

.four-card .head {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.four-card .fields p {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
  /* margin-top: 5px; */
}

.four-card .fields lable {
  font-size: 0.9rem;
  color: #606060;
}

.four-card .fields {
  /* display: flex; */
  padding: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-top: 1rem;
}

.four-card .head .icon {
  width: 40px;
  height: 40px;
  padding: 5px;
  background: var(--theme-color-main);
  color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 1.5rem;
  margin-right: 0.7rem;
}

.four-card .notification-card p {
  margin-bottom: 0;
}

.four-card .notification-card {
  height: 150px;
  overflow-y: auto;
  margin-top: 1rem;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.four-card .notification-card {
  scrollbar-width: 5px;
  scrollbar-color: var(--theme-color);
}

/* Chrome, Edge, and Safari */
.four-card .notification-card::-webkit-scrollbar {
  width: 6px;
}

.four-card .notification-card::-webkit-scrollbar-track {
  background: #ffffff;
}

.four-card .notification-card::-webkit-scrollbar-thumb {
  background-color: var(--theme-color);
  border-radius: 10px;
  /* border: 3px solid #ffffff; */
}

.order-card {
  border: 1px solid var(--theme-color);
  border-radius: 5px;
  margin: 0;
  padding: 1rem 0.2rem;
}

.order-card .border {
  padding: 1rem;
  border-radius: 5px;
}

.address-card .fields,
.order-card .border .fields {
  display: flex;
  align-items: end;
  margin-bottom: 10px;
  justify-content: space-between;
}

.order-card .border .fields:last-child {
  margin: 0;
}

.address-card p,
.order-card .border .fields p {
  margin: 0;
}

.order-card .border .fields lable {
  /* font-size: .8rem; */
  color: #8d8b8b;
  /* margin-right: 10px; */
}

.order-inner-card {
  border: 1px solid #cebeb1;
  height: 100% !important;
  border-radius: 10px;
  overflow: hidden;
}

.order-inner-card img {
  height: auto;
  aspect-ratio: 1/1;
  object-fit: contain;
  border-bottom: 1px solid #cebeb1;
}

.order-inner-card .content {
  padding: 10px;
}

.order-inner-card p {
  margin-bottom: 0;
}

.order-inner-card span {
  color: #747373;
  font-size: 0.9rem;
}

.address-card {
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid var(--theme-color-main) !important;
  overflow: hidden;
}

.address-card .add-edit-del-btns {
  position: absolute;
  right: 0;
  top: 0;
  color: var(--theme-color-main);
  border: 1px solid var(--theme-color-main);
  border-top: 0;
  border-right: 0;
  border-radius: 0 0 0 5px;
  cursor: pointer;
  font-size: 1.2rem;
}

.address-card .add-edit-del-btns span {
  padding: 2px 7px;
}

.address-card .add-edit-del-btns span:first-child {
  border-right: 1px solid var(--theme-color-main);
}


.edit-address {
  position: absolute;
}

.address-card span svg {
  margin-top: -3px;
}

.frequently-card .theme-btn {
  padding: 5px 10px;
  font-size: 0.8rem;
}