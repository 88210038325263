.paymen-section {
    /* background: url(../img/bg-1.png) no-repeat center;
    background-size: cover; */
}

.payment-form-img {
    width: 200px;
    height: 200px;
}

.payment-form {
    border-radius: 5px;
}

/* .payment-inst li{
    margin-right: 2rem;
}
.payment-inst li::marker {
    height: 20px;
    width: 30px;
    background-color: var(--theme-color-main);
} */
.payment-inst {
    list-style: none;
    counter-reset: steps;
}

.payment-inst li {
    counter-increment: steps;
    margin-right: 2rem;
    display: flex;
    align-items: center;
}

.payment-inst li p {
    margin-bottom: 0;
}

.payment-inst li::before {
    content: counter(steps);
    margin-right: 0.5rem;
    background: var(--theme-color-main);
    color: white;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    display: inline-grid;
    place-items: center;
    line-height: 2em;
}

.pay-img {
    height: 846px;
}

@media (max-width:568px) {
    .payment-inst li {
        margin-bottom: 1rem;
    }
}