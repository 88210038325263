.swiper {
  width: 100%;
  height: auto;
  position: relative;
  top: 0;
  left: 0;
}

.homebanner img {
  object-fit: cover;
  /* height: 90vh; */
  /* object-position: bottom; */
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  /* display: block;
  width: 100%; */
}

.logo-rms {
  height: 115px !important;
  border-radius: 50%;
}

.bg-1 {
  background: url(../../img/bg-2.png) no-repeat center;
  background-position-x: -200px;
  background-size: cover;
  /* background-color: var(--theme-color-main); */
}

.slide-1 h1 {
  line-height: 3rem;
}

.slide-1 p {
  line-height: 1.7rem;
}

.content-banner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50%;
}

.content-banner p,
.content-banner a {}

.content-banner a {
  padding: 20px 40px;
  background: var(--theme-color-button) !important;
  display: inline-block;
  font-family: "Poppins", sans-serif !important;
}

@media (min-width: 1550px) {
  .bg-1 {
    background-position-x: center;
  }
}

.swiper-button-prev,
.swiper-button-next {
  color: var(--theme-color-main);
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 1.5rem;
}

.swiper-pagination-bullet-active {
  background-color: var(--theme-color-main);
}

.main-slider-skeleton {
  height: 80vh;
}

.book-now a {
  background: var(--theme-color-button) !important;
  padding: 10px 15px !important;
  border-radius: 50px;
  color: #fff !important;
}

.homebanner {
  position: relative !important;
  display: flex;
  flex-direction: column;
}

@media (max-width: 992px) {
  .slid-img {
    position: absolute;
    z-index: 0;
    opacity: 0.3;
  }

  .slide-1 {
    z-index: 1;
  }

  .slide-1 p {
    font-weight: 500;
  }

  .swiper {
    height: auto;
  }

  .bg-1 {
    background-position-x: -400px;
  }

  .content-banner {
    position: static;
    top: 50%;
    transform: translateY(0);
    width: 100%;
    position: static;
    height: auto;
  }

  .homebanner .logo-rms {
    /* height: auto; */
    height: 100px !important;

  }
}

@media (max-width: 586px) {
  .main-slider-skeleton {
    height: auto;
    aspect-ratio: 1/1;
  }

  .content-banner {
    position: absolute;
    top: 50%;
    transform: translateY(0);
    width: 100%;
    position: static;
  }
}