.parallax-section {
  /* background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(../img/parallax.jpg); */
  height: 80vh;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-attachment: fixed !important;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.parallax-content h1 {
  color: #fff;
}

.view-more a {
  display: flex;
  align-items: center;
  /* font-size: .8rem; */
  background: var(--theme-color-main);
  color: #fff !important;
  padding: 10px 15px;
  text-align: center;
  border-radius: 50px;
  width: fit-content;
  cursor: pointer;
}

.shop-now a {
  display: flex;
  align-items: center;
  /* font-size: .8rem; */
  background: var(--theme-color-button);
  color: #fff !important;
  padding: 20px 40px;
  text-align: center;
  border-radius: 50px;
  width: fit-content;
  cursor: pointer;
}

@media (max-width: 992px) {
  .parallax-section {
    height: 40vh;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
  }
}

@media (max-width: 1500px) {
  .parallax-section {
    height: 40vh;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
  }
}

@media (max-width: 1200px) {
  .parallax-section {
    height: 40vh;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
  }
}
@media (max-width: 568px) {
  .parallax-section {
    height: auto;
    padding: 50px 0;
  }
}
