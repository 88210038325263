.products-page .page-title-area {
    background: url("../img/inner-banner/2.png");
}

.gallery-page .page-title-area {
    background: url("../img/banners/Gallery.jpg");
    background-position: center;
}

.products-page .r-card {
    font-size: 1.4rem;
    line-height: 3rem;
    display: inline-block;
    text-transform: capitalize;
}

@media (max-width:568px) {
    .products-page .r-card a p {
        line-height: 1.7 !important;
    }

    .inner-section .col-lg-3 {
        margin-bottom: 1.5rem;
    }
}