.faq-page .page-title-area {
    background: url(../img/banners/faq.jpg);
}
.faq-accordion .ant-collapse {
    background: transparent !important;
    border: none !important;
    border-radius: 0 !important;
}

.faq-accordion .ant-collapse-header {
    flex-direction: row-reverse !important;
}

.faq-accordion .ant-collapse .ant-collapse-content>.ant-collapse-content-box {
    padding: 1rem;
}

.faq-accordion .ant-collapse .ant-collapse-content>.ant-collapse-content-box p {
    margin: 0;
}

.faq-accordion :where(.css-dev-only-do-not-override-mzwlov).ant-collapse>.ant-collapse-item:last-child,
.faq-accordion :where(.css-dev-only-do-not-override-mzwlov).ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
    border-radius: 0 !important;
}

.faq-accordion .ant-collapse-header-text h6 {
    font-family: inherit !important;
    font-size: 1rem;
}