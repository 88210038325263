.blog-para-content {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.blog-section-swiper .swiper-slide p {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.home-blog img {
    height: 300px;
    width: 100%;
    object-fit: cover;
}
.blog-skeleton .ant-skeleton-image {
    aspect-ratio: 1/.7;
}
.blog-short-desc {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.blog-short-desc p {
    margin-bottom: 0 !important;
}