.horoscope-page .page-title-area {
  background: url("../img/banners/Horoscope-Banner.jpg");
  background-position: center;
}

.horoscope-card {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/blog/blogaries.png);
  background-size: cover;
}

/* 
.lucky-color {
  color: var(--theme-color-button);
}

.lucky-number {
  color: var(--theme-color-button);
} */

.horoscope-section .service-card p {
  display: flex;
  text-align: left;
  justify-content: left;
  text-align: justify;
}

.services-swiper .swiper-slide {
  height: auto !important;
}

.services-swiper .swiper-slide>div {
  height: 100%;
}

.services-swiper .service-card {
  flex-direction: column;
  display: flex;
  /* align-content: space-between; */
  /* flex-wrap: wrap; */
  justify-content: space-around;
}

.color-box {
  height: 20px;
  width: 20px;
}


.horoscope-page-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.book-now a {
  background-color: var(--theme-color-button);
  color: #fff;
}