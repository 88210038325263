.home-gallery-swiper .swiper-slide div {
    aspect-ratio: 1/1;
    /* height: auto; */
}

.home-gallery-swiper .swiper-slide img {
    height: 100%;
    object-fit: cover;
    width: 100% !important;
    border-radius: 10px;
}