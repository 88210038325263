.why-choose-section .col-lg-4 {
  margin-bottom: 30px;
}

.section-head {
  margin-bottom: 60px;
}

.section-head h4,
.section-head h1 {
  position: relative;
  padding: 0;
  color: #410f58;
  line-height: 1;
  letter-spacing: 0.3px;
  font-size: 34px;
  font-weight: 700;
  text-align: center;
  text-transform: none;
  margin-bottom: 30px;
}

.section-head h4:before,
.section-head h1:before {
  content: "";
  width: 60px;
  height: 3px;
  background: #410f58;
  position: absolute;
  left: 0px;
  bottom: -10px;
  right: 0;
  margin: 0 auto;
  border-radius: 5px;
}

.section-head h4 span,
.section-head h1 span {
  font-weight: 700;
  padding-bottom: 5px;
  color: #2f2f2f;
}

p.service_text {
  color: #cccccc !important;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
}

.section-head p,
p.awesome_line {
  color: #818181;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
}

.extra-text {
  font-size: 34px;
  font-weight: 700;
  color: #2f2f2f;
  margin-bottom: 25px;
  position: relative;
  text-transform: none;
}

.extra-text::before {
  content: "";
  width: 60px;
  height: 3px;
  background: var(--theme-color-main);
  position: absolute;
  left: 0px;
  bottom: -10px;
  right: 0;
  margin: 0 auto;
}

.extra-text span {
  font-weight: 700;
  color: var(--theme-color-main);
}

.item {
  background: #fff;
  text-align: center;
  padding: 30px 25px;
  -webkit-box-shadow: 0 0px 25px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0px 25px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  border: 5px solid rgba(0, 0, 0, 0.07);
  height: 100%;
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}

.item:hover {
  background: var(--theme-color-main);
  box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}

.item:hover .item,
.item:hover span.icon {
  background: #fff;
  border-radius: 10px;
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}

.item h6 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.item:hover h6,
.item:hover p {
  color: #fff;
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}

.main-card-section {
  padding: 1rem 2rem !important;
  border: 1px solid #e3e3e3;
  border-radius: 1rem;
}

.three-section-card {
  padding: 1rem;
  height: 100%;
}

.t-card-img img {
  border-radius: 10px;
  /* margin-bottom: 1rem; */
  /* height: 330px; */
  object-fit: cover;
  /* object-position: 0 -100px; */
}

.ts-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-bottom: 1rem; */
}

.ts-icon svg {
  font-size: 3rem;
  margin-right: 1rem;
  color: var(--main-color);
}

.three-section-card-1 {
  border-bottom: 1px solid #e5e5e5;
}

.three-section-card-2 {
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.three-section-card-3 {
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.three-section-card-4 {
  border-left: 1px solid #e5e5e5;
  position: relative;
}

.three-section-card-4 svg {
  font-size: 20rem;
  position: absolute;
  left: 50%;
  top: 50%;
  color: #18191912;
  transform: rotate(10deg);
  transform: translate(-50%, -50%);
}

.three-section-card h3 {
  font-weight: 500;
  text-align: center;
  margin-top: 2rem;
  font-size: 1.2rem;
}

.three-section-card-4 h3 span {
  color: var(--main-color);
  font-weight: bold;
}

.three-section-card .default-btn:hover {
  background-color: var(--main-color);
  color: var(--white-color);
}

.card-section p {
  font-size: 1.2rem;
}

@media (max-width: 1399px) {

  /* .three-section-card h3 {
      font-size: 1.8rem;
    } */
  .three-section-card p {
    font-size: 1rem;
  }
}

/* @media (min-width: 1000px) and (max-width: 1199px) {
  } */
@media (max-width: 568px) {
  .three-section-card {
    border: none;
    border-bottom: 1px solid #e5e5e5;
    padding: 2rem 1rem;
  }

  .main-card-section {
    border: none;
    padding-right: calc(var(1.5rem) * 0.5) !important;
    padding-left: calc(var(1.5rem) * 0.5) !important;
    /* padding: 1rem; */
  }

  .main-card-section>.row {
    padding: 1rem !important;
    border: 1px solid #e3e3e3;
    border-radius: 1rem;
  }

  .t-card-img img {
    /* object-position: 0 -75px; */
  }

  .three-section-card-4 {
    border-bottom: none;
  }

  .three-section-card-5 {
    border: none;
    border-bottom: 1px solid #e5e5e5;
  }
}