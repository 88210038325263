a {
    text-decoration: none;
    color: #000;
}

.top-heading .main-product-page .section-head:nth-child(2) {
    display: none !important;
}

.bottom-heading .main-product-page .section-head:nth-child(1) {
    display: none !important;
}