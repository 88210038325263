.card {
  margin: 1rem;
}

@media (max-width:568px) {
  .card {
    width: 100% !important;
    margin: 2rem auto;
  }
}


.button {
  padding: 10px 30px;
  text-align: center;
  color: #fff !important;
  display: inline-block;
}

.button-2 {
  cursor: pointer !important;
  color: #fff;
  border: 3px solid var(--theme-color-main);
  background-image: -webkit-linear-gradient(30deg, var(--theme-color-main) 50%, transparent 50%);
  background-image: linear-gradient(30deg, var(--theme-color-main) 50%, transparent 50%);
  background-size: 600px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
  border-radius: 50px;
}

.button-2:hover {
  background-color: transparent;
  background-position: 150%;
  color: var(--theme-color-main) !important;
}

.service-card {
  border-radius: 10px;
  border: solid 1px var(--theme-color-main)2b;
  background-color: #fff;
  padding: 1.5em;
  /* text-align: center; */
  position: relative;
  /* margin-bottom: 2rem; */
  height: 100%;
  background: #f8f8f8;
}

.faded-image {
  position: absolute;
  right: 15px;
  opacity: .3;
  height: 40%;
  top: 10px;
}

.service-card-corporate {
  border-radius: 6px;
  border: solid 1px var(--theme-color-main)2b;
  background-color: #fff;
  padding: 2.5em;
  text-align: center;
  position: relative;
  /* margin-bottom: 2rem; */
  /* height: 100%; */
}

.service-card-corporate::before {
  content: "";
  position: absolute;
  background-color: var(--theme-color-main);
  top: 0;
  left: -1px;
  width: calc(100% + 2px);
  height: 8px;
  border-radius: 6px 6px 0 0;
}

/* 
.service-card::before {
  content: "";
  position: absolute;
  background-color: var(--theme-color-main);
  top: 0;
  left: -1px;
  width: calc(100% + 2px);
  height: 8px;
  border-radius: 6px 6px 0 0;
}
 */

/* new  */


.services-section .container-fluid {
  border-radius: 10px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../img/aboutus-banner.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.services-section .servicee-card {
  background-color: #fff;
  border: none !important;
}

.services-section .servicee-card img {
  object-fit: cover;
}

.services-section .swiper-pagination-bullet-active {
  background: var(--white-color) !important;
}

.service-content {
  padding: 8px;
  /* padding-bottom: 15px; */
}

.para-service p {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.read-more a {
  background: var(--theme-color-main);
  padding: 6px 15px;
  border-radius: 50px;
  color: #fff !important;
}

.book-now a {
  background: var(--theme-color-button) !important;
  padding: 6px 15px;
  border-radius: 50px;
  color: #fff !important;
}

/*  */
.astrology-image img {
  border-radius: 10px;
}

.handwritting-image img {
  border-radius: 10px;
}

.tarot-image img {
  border-radius: 10px;
}

.palmistry-image img {
  border-radius: 10px;
}

.numerology-image img {
  border-radius: 10px;
}

.reiki-image img {
  border-radius: 10px;
}

.content-color {
  background: var(--theme-color-button);
  border-color: #fff !important;
}

.content-color td {
  color: #000 !important;
  /* color: #fff; */
}


/* 

.services-swiper .servicee-card img {
  height: 63%;
} */

.services-swiper .servicee-card .service-content {
  height: 37%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width:900px) {
  .product-card img {
    object-fit: cover !important;
  }
}


@media (max-width:1400px) and (min-width:1100px) {
  .service-card {
    padding: 2rem 1rem;
  }
}

@media (max-width:1460px) {
  .services-swiper .servicee-card img {
    height: auto;
  }

  .services-swiper .servicee-card .service-content {
    height: auto;
  }
}

@media (max-width:568px) {
  .service-card {
    padding: 2rem 1.4rem;
  }
}