.about-page .page-title-area {
  background: url("../img/aboutus-banner.png");
}

.inner-section .three-section-card {
  text-align: center;
}

.inner-section .three-section-card {
  border: none;
}

.inner-section .three-section-card-1,
.inner-section .three-section-card-3 {
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.inner-section .three-section-card-2,
.inner-section .three-section-card-5,
.inner-section .three-section-card-7 {
  border-bottom: 1px solid #e5e5e5;
}

.inner-section .three-section-card-4 {
  border-right: 1px solid #e5e5e5;
  border-bottom: none;
}

.inner-section .ts-icon img {
  width: 150px !important;
  height: 150px;
}


.why-choose-us-card {
  border: 1px solid #cebeb1;
  border-radius: 10px;
  padding: 1rem;
}

.why-choose-us-card .card-inner-image {
  width: 25%;
}

.why-choose-us-card .card-inner-image img {
  height: 100px;
  margin-bottom: 10px;
}

.why-choose-us-card .why-choose-content {
  width: 75%;
}


.intro-video iframe {
  border-radius: 10px;
}

.why-choose-image img {
  height: 520px !important;
  /* margin-top: 10px;
  margin-bottom: 10px; */
}



.why-choose-image {
  display: flex;
  align-items: center;
  justify-content: center;
}


.about-card {
  /* border: 1px solid #cebeb1; */
  border-radius: 10px;
  padding: 15px;
  background-color: var(--theme-color-main);
  color: #fff;
}

.about-card h4 {
  margin-top: 5px;
}

.about-year {
  display: flex;
}

.about-year h3 {
  display: flex;
  align-items: center;
  justify-content: center;
}



.about-year img {
  border-radius: 10px;
  border: 1px solid #cebeb1;
  padding: 20px;
}

.about-year span.year {
  color: var(--theme-color-main);
  font-size: 50px;
  padding: 10px;

}

.about-card-image img {
  margin-bottom: 10px;
}

.why-choose-image img.spin {
  animation: animName 4s linear infinite;
  /* height: 350px !important; */
}

@keyframes animName {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



@media (max-width: 992px) {
  .inner-section .three-section-card {
    border: none;
    border-bottom: 1px solid #e5e5e5;
    padding: 2rem 1rem;
  }

  .inner-section .three-section-card-4 {
    border-bottom: none;
  }
  
  .inner-section .three-section-card-5 {
    border: none;
    border-bottom: 1px solid #e5e5e5;
  }
  
  .inner-section .about-section .content-column {
    margin: 0;
  }
  
  .inner-section .about-section {
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .why-choose-us-card {
    flex-direction: column;
  }
  
  .why-choose-us-card .card-inner-image,
  .why-choose-us-card .why-choose-content {
    width: 100%;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .about-card {
    height: 100% !important;
  }
  
  .why-choose-us-card .why-choose-content {
    width: 100% !important;
  }
  
  .why-choose-us-card .card-inner-image {
    width: 0 !important;
  }
}
@media (max-width: 568px) {
  .about-year h3{
    text-align: left;
  }
}