.inner-section iframe {
  /* height: 530px; */
  width: 100%;
}

.contact-card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.contact-card h3 {
  margin-bottom: 1rem;
}

.contact-card svg {
  font-size: 1.4rem;
  color: var(--theme-color-main);
  margin-right: 10px;
}

.contact-card a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.appointment-page .page-title-area {
  background: url("../img/home-banner.webp");
}

.contact-form iframe {
  height: 930px;
}

.icon {
  border: 1px solid #000;
  /* border-radius: 50px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center; */
}

/*  */
.contact-info-box {
  display: flex;
  margin-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e3e3e3;
}

.contact-info-box a {
  display: block;
}

.contact-info-box p {
  margin-bottom: 0;
  font-size: 1.2rem;
  font-weight: 400;
}

.contact-info-box .icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: var(--theme-color-main);
  font-size: 1.8rem;
  margin-right: 1rem;
}

.contact-info-box .icon:first-child svg {
  width: 60px;
}

.contact-form input {
  padding: 0.5rem;
}

.captcha-box {
  position: relative;
}

.captcha-box .captcha {
  position: absolute;
  right: 10px;
  top: 7px;
  z-index: 9;
  cursor: pointer;
}

.ant-input-outlined {
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  color: #000 !important;
  width: 100% !important;
}

.ant-picker .ant-picker-suffix,
.ant-picker .ant-picker-input>input::placeholder,
.ant-input-outlined::placeholder {
  color: #000 !important;
}

.ant-picker-dropdown {
  z-index: 999999 !important;
}

.ant-picker-content th {
  color: #fff !important;
}

.appointment-form-modal {
  z-index: 99999 !important;
}

.book-app-btn-box p {
  width: 60%;
}

.book-app-btn-box a {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .inner-section iframe {
    height: 255px;
  }

  .book-app-btn-box p {
    width: 100%;
    margin-bottom: 15px !important;
    font-size: 14px;
  }

  .book-app-btn-box a {
    width: 100%;
    justify-content: center;
  }
}

/* css for date picker and time picker */
.date-picker-appionment .ant-picker {
  width: 100% !important;
  padding: 8px;
}

.form-select:disabled {
  background-color: transparent !important;
}

:where(.css-dev-only-do-not-override-1hpnbz2).ant-input-outlined.ant-input-disabled,
:where(.css-dev-only-do-not-override-1hpnbz2).ant-input-outlined[disabled] {
  background-color: transparent !important;
}

.form-control:disabled {
  background-color: transparent !important;
}

:where(.css-dev-only-do-not-override-1hpnbz2).ant-picker-outlined.ant-picker-disabled,
:where(.css-dev-only-do-not-override-1hpnbz2).ant-picker-outlined[disabled] {
  background-color: transparent !important;
}

.form-select {
  font-size: .9rem !important;
  padding: 8px 5px !important;
}

.react-international-phone-input-container .react-international-phone-input {
  font-size: .9rem;
  width: 100%;
}

.react-international-phone-country-selector-button--disabled {
  background-color: transparent;
}

.react-international-phone-input-container .react-international-phone-input--disabled {
  background-color: transparent;
}

:where(.css-dev-only-do-not-override-1hpnbz2).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-1hpnbz2).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled) .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-1hpnbz2).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
  background: var(--theme-color-main) !important;
}

:where(.css-dev-only-do-not-override-1hpnbz2).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: var(--theme-color-main) !important;
}

:where(.css-dev-only-do-not-override-1hpnbz2).ant-btn-variant-solid {
  background: #1677ff !important;
  color: #fff !important;
}

select option:hover {
  background-color: var(--theme-color-main) !important;
}

/* 
.select-service-box {
  position: relative;
}



.select-service-box::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 10;
}

.select-service-box .select-service-option {
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99 !important;
} */