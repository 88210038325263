.page-title-area {
  position: relative !important;
  z-index: 1;
  background-image: url("../img/home-banner.webp");
  background-position: center center;
  background-size: cover !important;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  height: 300px;
}

.shop-online p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.services-swiper .service-card p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
