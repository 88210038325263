.remedies-swiper {
    height: auto;
}

.r-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border: 2px solid rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    transition: .5s;
    height: 87%;
    text-align: center;
}

.r-card .r-img {
    overflow: hidden;
    border-radius: 8px;
}

.r-card img {
    border-radius: 8px;
    transition: .5s;
    width: 100%;
}

.r-card>a {
    font-size: 1.4rem;
    margin-top: 10px;
    display: inline-block;
    text-transform: capitalize;
}

.r-card:hover {
    border-color: var(--theme-color-main)8e;
}

.r-card:hover img {
    transform: scale(1.1);
}