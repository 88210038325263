.footer-policy-card {
    display: flex;
    padding: 1rem;
    border: 1px solid #e3e3e3;
    background: #f8f8f8;
    align-items: center;
    border-radius: 10px;
    height: 100%;
}


.footer-policy-card img {
    width: 100px;
}

.footer-policy-card .content {
    margin-left: 1rem;
    color: var(--theme-color-main);
    align-self: flex-start;
}

.footer-policy-card p {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0;
    color: var(--theme-color-main);
}

.footer-pilocy-slider .swiper-slide {
    height: auto;
}