.blogsection .service-card p {
  display: flex;
  text-align: left;
  justify-content: left;
  text-align: justify;
}

p.awesome_line {
  color: #818181;
  font-size: 16px;
  line-height: 28px;
  text-align: left;
}

.blog-page .page-title-area {
  background: url("../img/banners/Blog-Banner.jpg");
}

.blog-page-image img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.virgo-image img {
  height: 400px;
  object-fit: cover;
}

/* .ariesimage {
  height: 55vh;
} */

.bulletpoints li {
  margin-bottom: 1rem;
}

.aries-image {
  border-radius: 10px;
}

@media (max-width: 1200px) {
  .blog-page-image img {
    height: 250px;
  }
}
@media (max-width: 568px) {
  .virgo-image img {
    height: auto;
  }
}
