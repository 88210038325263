.cart-page.page-title-area {
  background: url("../img/home-banner.webp");
}

.checkout-img {
  width: 100%;
  /* height: 100px; */
  aspect-ratio: 1/1;
  margin: auto;
  border-radius: 0.3rem;
}

.checkout-details p {
  font-size: 20px;
}

.checkout-details h6 {
  margin-bottom: 5px;
  margin-top: 15px;
}

.order-summary-table {
  font-size: 15px;
  width: 100%;
}

.order-summary-table tr td {
  padding: 8px 0;
  cursor: initial !important;
}

.grand-total {
  font-size: 17px;
}

.grand-total td {
  padding-top: 20px !important;
  font-weight: 300;
}

.delete-cart-card-btn {
  position: absolute;
  top: -15px;
  right: -25px;
  font-size: 1.5rem;
}

.quantity {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 0;
}

.quantity__minus:link,
.quantity__plus:link {
  color: #8184a1;
}

.quantity__input {
  width: 32px;
  height: 23px;
  margin: 0;
  padding: 0;
  text-align: center;
  border-top: 2px solid #dee0ee;
  border-bottom: 2px solid #dee0ee;
  border-left: 1px solid #dee0ee;
  border-right: 2px solid #dee0ee;
  background: var(--theme-color-white);
  color: #8184a1;
  font-size: 12px;
}

.quantity__minus,
.quantity__plus {
  display: block;
  width: 22px;
  height: 23px;
  margin: 0;
  background: #eeeeee;
  text-decoration: none;
  text-align: center;
  line-height: 23px;
}

.prod-price {
  color: var(--black-color);
  font-weight: 700;
}

.c-price del {
  color: #928f8f;
  font-size: 13px;
}

.order-summary {
  /* border: 1px solid #EAEAEA; */
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 25px;
  border-radius: 5px;
  /* background: #F7F7F7; */
  border: none;
  border: 1px solid #d9d9d9;
}

.order-summary.sticky-top {
  transition: 0.3s;
  z-index: 0 !important;
}

.order-summary h5 {
  font-size: 20px;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
}

.order-summary-table {
  font-size: 15px;
  width: 100%;
}

.order-summary-table tr td {
  padding: 8px 0;
}

.order-summary-table {
  font-size: 15px;
  width: 100%;
}

.order-summary-table tr td {
  padding: 8px 0;
  list-style: none;
  border-bottom: 1px solid #ccc;
  width: auto;
}

.coupon-card-box {
  width: 100%;
  padding: 10px;
  border: 1px solid #adaaaa;
  border-radius: 5px;
  border-left: 6px solid var(--black-color);
  border-right: 6px solid var(--black-color);
}

.coupon-card-box p.font-weight-bold {
  font-size: 1.2rem;
}

.coupon-card-box svg {
  font-size: 2rem;
}

.cartcheckout-accordion {
  border-radius: 5px !important;
  font-family: inherit !important;
  background: var(--theme-color-white) !important;
}

.cartcheckout-accordion .ant-collapse-header {
  border-radius: 12px 12px 0 0;
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.checkbox input {
  margin-right: 10px;
}

.address-links {
  margin-top: 10px;
}

.address-links a {
  margin-right: 10px;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: transparent !important;
  border: none !important;
}

.checkout-bx {
  padding: 15px 0;
  border-bottom: 1px solid #f9f6f6;
}

.checkout-bx .position-relative:last-child hr {
  display: none;
}

.checkout-bx:last-child {
  border-bottom: none;
}

.checkout-img {
  width: 100%;
  /* height: 100px; */
  aspect-ratio: 1/1;
  margin: auto;
  border-radius: 0.3rem;
}

.checkout-details p {
  font-size: 20px;
}

.checkout-details h6 {
  margin-bottom: 5px;
  margin-top: 15px;
}

.order-summary-table {
  font-size: 15px;
  width: 100%;
}

.order-summary-table tr td {
  padding: 8px 0;
  cursor: initial !important;
}

.grand-total {
  font-size: 17px;
}

.grand-total td {
  padding-top: 20px !important;
  font-weight: 300;
}

.checkout-btn {
  background: #ee1b2f;
  color: #fff;
  box-shadow: none;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  display: inline-block;
  box-shadow: 2px 2px 10px rgb(10 10 10 / 11%);
  border-radius: 3px;
}

.checkout-btn:hover {
  color: #ee1b2f;
  background: white;
  border: 1px #ee1b2f solid;
  border-radius: 3px;
}

.boxes {
  /* padding: 1rem; */
  margin-bottom: 20px;
  border: 1px solid #eeeeee;
  border-width: 1px;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.boxes input {
  margin-right: 5px;
}

.boxes p {
  margin-bottom: 0;
}

.address-box {
  font-size: 14px;
}

.address-box p {
  margin-bottom: 10px;
}

.address-box p b {
  font-weight: 500 !important;
  font-size: 1.1rem !important;
}

.address-box input {
  margin-right: 10px;
}

.order-summary {
  /* border: 1px solid #EAEAEA; */
  /* padding: 20px; */
  padding: 0;
  margin-top: 20px;
  margin-bottom: 45px;
  border-radius: 5px;
  /* background: #F7F7F7; */
  border: none;
  border: 1px solid #d9d9d9;
  overflow: hidden;
  border-bottom: 4px solid var(--black-color);
}

.order-summary>div {
  padding: 20px;
}

.order-summary.sticky-top {
  transition: 0.3s;
  z-index: 0 !important;
}

.order-summary .order-summary-table td svg {
  margin-right: 5px;
}

.order-bottom-card {
  padding: 10px 20px !important;
  line-height: 1.2;
  background: #f3f3f3;
}

.order-summary h5 {
  font-size: 20px;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
}

.order-summary-table {
  font-size: 15px;
  width: 100%;
}

.order-summary-table tr td {
  padding: 8px 0;
}

.order-summary-table {
  font-size: 15px;
  width: 100%;
}

.order-summary-table tr td {
  padding: 8px 0;
}

.checkout-box {
  border: none;
}

.btnicons {
  width: 20px;
}

.address-box-profile {
  border: 1px solid #eeeeee;
  padding: 60px;
  height: 96%;
}

.address-card {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  /* box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%); */
  border: 1px solid var(--theme-color-dark);
}

/* //////////Select address end /////////////// */

/* ===========Login.js=========== */

.rgbox-bottom {
  position: relative;
}

.rgbox-bottom p::before {
  border-bottom: 1px solid #767676;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 10px;
  margin: 0 auto;
}

.rgbox-bottom p label {
  background-color: #fff;
  margin-bottom: 0px;
  color: #767676;
  position: relative;
  padding: 0px 5px;
}

.but button {
  margin-top: 10px;
  width: 100%;
}

.cart-box .account-dropdown-content {
  position: absolute;
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  min-width: 180px;
  box-shadow: 0 2px 3px 0 rgb(34 36 38 / 15%);
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 20px 20px 20px 30px;
  z-index: 9999;
  margin-top: 15px;
  transform: translateY(-2em);
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
}

.but-button {
  margin-top: 10px;
  width: 100%;
  background: white;
  color: #50b05d;
  border: 1px solid #50b05d;
}

.but-button :hover {
  background: #50b05d !important;
  color: white !important;
  border: 1px solid #50b05d !important;
}

.ant-collapse .ant-collapse-content>.ant-collapse-content-box {
  padding: 0;
}

@media (max-width: 568px) {
  .delete-cart-card-btn {
    position: absolute;
    top: -15px;
    right: 8px;
  }

  .quantity__input {
    width: 50%;
  }

  .quantity__minus,
  .quantity__plus {
    width: 30%;
  }

  .cquantity {
    margin-top: 10px !important;
  }

  .add-new-address {
    font-size: 0.8rem;
    padding: 10px;
    margin-left: 10px;
  }
}

.cartcheckout-accordion .ant-collapse-item-active .ant-collapse-header {
  background: #f3f3f3;
  border-radius: 5px;
}

.cart-accordian-icons {
  font-size: 25px;
}

.cartcheckout-accordion .ant-collapse-header .cart-accordian-icons {
  padding: 5px;
  background-color: #f5f3f0;
  border-radius: 50%;
  transition: 0.4s all;
  color: var(--theme-color-dark);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}

.cartcheckout-accordion .ant-collapse-item-active .ant-collapse-header .cart-accordian-icons {
  background-color: var(--white-color);
}

.address-offcanvas {
  width: 35% !important;
}

.cart-address-icons {
  font-size: 35px;
  display: flex;
  justify-content: center;
}

.cartcheckout-accordion .ant-collapse-item .add-addres-btn {
  display: none;
  margin-left: auto;
  margin-right: 1rem;
}

.cartcheckout-accordion .ant-collapse-item-active .add-addres-btn {
  display: inline-block;
}

.ant-collapse-header-text .main-heading {
  margin-bottom: 0;
}

.add-btn-mobile.disabled {
  background: #e3e3e3 !important;
  cursor: not-allowed;
}

@media (max-width: 568px) {
  .address-offcanvas {
    width: 100% !important;
  }
}

@media (max-width: 380px) {
  .add-list-box {
    flex-direction: column;
    gap: 10px;
  }

  .boxes .d-flex .d-flex {
    align-items: center;
  }
}