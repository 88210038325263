.book-card {
  padding: 1rem;
  border: 5px solid rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  transition: 0.5s;
}

.book-card .image {
  overflow: hidden;
  border-radius: 8px;
}

.book-card img {
  border-radius: 8px;
  transition: 0.5s;
  width: 100%;
}

.book-card>a {
  font-size: 1.4rem;
  margin-top: 10px;
  display: inline-block;
  text-transform: capitalize;
}

.book-card:hover {
  border-color: var(--theme-color-main) 8e;
}

.image-book-card img {
  height: 312px;
  object-fit: contain;
}

.rupees {
  font-size: 25px;
}

/* new */
.video-box {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.video-box .video-card {
  width: 19%;
  /* padding: 0 10px 0 10px; */
}

.video-card video {
  width: 100%;
  border-radius: 10px;
}

.insta-icon a {
  color: #fff !important;
  font-size: 4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}