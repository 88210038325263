a {
    color: #000 !important;
    transition: .3s;
}

a:hover {
    color: var(--theme-color-main) !important;
}

.back-to-top {
    z-index: 99999;
}

.myBtn {
    border-radius: 10px;
    border: none;
    width: 40px !important;
    height: 40px !important;
    justify-content: center;
    background-color: var(--theme-color-main);
    position: relative;
    z-index: 999999;
    transition: .3s;
    border: 3px solid var(--theme-color-main);
}

.myBtn:hover {
    background-color: transparent;
}

.arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    height: 25px;
    width: 25px;
    color: #fff;
    /* padding-bottom: 40px; */
    z-index: 999;
}

.myBtn:hover .arrow {
    color: var(--theme-color-main);
}

@media (max-width: 1200px) {
    .myBtn {
        border-radius: 10px;
        border: none;
        width: 40px !important;
        height: 40px !important;
        justify-content: center;
        background-color: var(--theme-color-main);
        position: relative;
        z-index: 999999;
        transition: .3s;
        border: 3px solid var(--theme-color-main);
    }
}