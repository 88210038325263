.static-page .page-title-area {
  position: relative !important;
  z-index: 1;
  background-image: url("../img/banners/8.jpg");
  background-position: center center;
  background-size: cover !important;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  height: 300px;
}

.static-pages ul li a {
  overflow-x: auto;
  width: 100%;
  display: inline-block;
}
.static-pages ul li a,
.static-pages ul li a span {
  color: #000 !important;
  white-space: inherit;
}
