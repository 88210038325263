footer {
  background: var(--theme-color-main);
  color: #fff;
}

footer a:hover {
  color: #fff !important;
}

footer ul {
  list-style: none;
}

footer .footer-logo img {
  background: #fff;
  border-radius: 50%;
}

.footer-bottom {
  color: #fff;
  background: var(--theme-color-main);
  /* padding-bottom: 4rem !important; */
}

.main-nav-menu-links li {
  margin-right: 10px;
}

.read-more a {
  background: var(--theme-color-main);
  padding: 10px 15px;
  border-radius: 50px;
  color: #fff !important;
}

.footer-bottom a,
.footer-bottom p,
footer a,
footer p {
  color: #fff !important;
}

.contact-links {
  display: flex;
  justify-content: flex-end;
}

footer .contact-links li>a {
  width: 35px;
  height: 35px;
  padding: 5px;
  background-color: var(--theme-color-main);
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

footer .contact-links li:last-child>a {
  margin-right: 0;
}

footer .contact-links li svg {
  color: #fff;
}

.payments-icons {
  display: flex;
  gap: .7rem;
  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: center;
}

.payments-icons img {
  width: 50px;
}


.footer-sm-icons {
  /* position: fixed;
  left: 1rem;
  bottom: 1.5rem; */
  display: flex;
  /* z-index: 999; */
  padding: 0;
  margin: 0;
}

.footer-sm-icons a {
  /* width: 35px;
  height: 35px;
  padding: 5px;
  background-color: var(--theme-color-main); */
  /* border-radius: 50%; */
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-sm-icons a svg {
  font-size: 1.5rem;
  color: #fff;
}

footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-sm svg {
  width: 30px;
  height: 30px;
}

.footer-logo img {
  width: 150px;
  margin-top: -15px;
  margin-bottom: -15px;
}

.f-book-link {
  display: inline-block;
  margin-top: 5px !important;
  text-decoration: underline !important;
  text-underline-offset: 8px;
}

/* 
.number svg {
  border: 1px solid #000;
  background: var(--theme-color-button);
  border-radius: 50px;
  padding: 8px;
  height: 35px;
  width: 35px;
} */

/* ****** Ask consukt btn ****** */

.floating_btn a {
  text-decoration: none;
}

.floating_btn {
  position: fixed;
  bottom: 90px;
  right: 31px;
  /* width: 100px; */
  /* height: 100px; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
  z-index: 99999;
  transition: 2s;
  transform: translateY(0);
}

.hide-footer-btn {
  transform: translateY(500px);
}

.call_icon {
  bottom: 180px;
}

.call_icon a {
  position: relative;
  color: #fff !important;
  width: auto !important;
  padding: 10px 15px !important;
  background-image: -webkit-linear-gradient(30deg, var(--theme-color-button) 50%, transparent 50%);
  background-image: linear-gradient(30deg, var(--theme-color-button) 50%, transparent 50%);
  background-size: 600px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
  border-radius: 50px;
  display: inline-block;
  bottom: 8px;
}

.call_icon a:before {
  position: absolute;
  content: " ";
  z-index: -1;
  top: 0;
  left: 0;
  background-color: var(--theme-color-button);
  width: 100%;
  height: 100%;
  border-radius: 50px;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  opacity: 0.6;
  -webkit-animation: pulse 1s ease-out;
  animation: pulse 1.8s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;

}

.call_icon svg {
  font-size: 1.5rem;
  margin-right: 5px;
}

.whatsapp-btn {
  width: 50px;
  height: 50px;
  position: relative;
  color: #fff !important;
  /* padding: 10px 15px !important; */
  background-image: -webkit-linear-gradient(30deg, #30d14e 50%, transparent 50%);
  background-image: linear-gradient(30deg, #30d14e 50%, transparent 50%);
  background-size: 600px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}

.whatsapp-btn:hover {
  color: #fff !important;
}

.whatsapp-btn:before {
  position: absolute;
  content: " ";
  z-index: -1;
  top: 0;
  left: 0;
  background-color: #30d14e;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  opacity: 0.6;
  -webkit-animation: pulse 1s ease-out;
  animation: pulse 1.8s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;

}

.floating_btn .whatsapp-btn {
  /* padding: 10px !important; */

}

.floating_btn .whatsapp-btn svg {
  margin: 0;
}

.ul-li li {
  margin-bottom: 6px;
  padding: 0;
  cursor: pointer;
}

/* 
 */
/*  */
.social-icon-bullet {
  font-size: 1.2rem;
}



@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(0.7);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(1);
    opacity: .6;
  }

  75% {
    -webkit-transform: scale(1.2);
    opacity: .3;
  }

  100% {
    -webkit-transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  25% {
    transform: scale(0.7);
    opacity: 1;
  }

  50% {
    transform: scale(1);
    opacity: .6;
  }

  75% {
    transform: scale(1.2);
    opacity: .3;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.call_icon a:hover {
  color: #fff !important;

}

.floating_btn .button {
  cursor: pointer !important;
  color: #fff;
  border: 3px solid var(--theme-color-main);
  background-image: -webkit-linear-gradient(30deg, var(--theme-color-main) 50%, transparent 50%);
  background-image: linear-gradient(30deg, var(--theme-color-main) 50%, transparent 50%);
  background-size: 600px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
  border-radius: 50px;
  display: inline-block;
}

.floating_btn .button:hover {
  background-color: transparent;
  background-position: 150%;
  color: var(--theme-color-main) !important;
}

.floating_btn .bounce {
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@-webkit-keyframes bounce {

  20%,
  53%,
  80%,
  0%,
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes bounce {

  20%,
  53%,
  80%,
  0%,
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0, -4px, 0);
  }
}

@media (max-width:578px) {
  .payments-icons {
    /* justify-content: center; */
    margin: 1rem 0;
  }

  .footer-sm-icons {
    /* justify-content: center; */
  }

  .footer-bottom {
    font-size: .9rem;
    padding-bottom: 1rem !important;
  }

  footer .main-nav-menu-links>li {
    text-align: left !important;
    padding: 0 !important;
  }

  .social-icon-bullet {
    font-size: 1rem;
  }

  .email {
    font-size: 12px;
  }

}