a {
  text-decoration: none !important;
  color: #000;
}

.navbar-brand img {
  width: 70px;
  object-fit: contain;
}

.nav-container {
  width: 100%;
  position: absolute;
  top: 43px;
  left: 0;
  z-index: 999;
  background: transparent;
}

.nav-container.fixed-top {
  box-shadow: 0 0 10px #1a1a1a26;
  background-color: rgb(255, 255, 255, 0.9);
}

.nav_links {
  width: 100%;
  /* position: absolute !important;
    top: 10% !important;
    z-index: 999;
    display: inline-block !important;
    background: transparent !important; */
}

.offcanvas-body .nav-link:nth-child(1) {
  padding-left: 3px !important;
}

.nav-sm-icon a {
  font-size: 1.5rem;
}

/* .top-sm-icons a svg {
  width: 30px;
  height: 30px;
} */

/* 
.top-sm-icons a:first-child svg {
  height: 44px;
  width: 36px;
} */

.top-sm-icons a {
  margin-right: 0.3rem;
  font-size: 25px;
  margin-top: -4px;
}

.top-sm-icons a:last-child {
  margin: 0;
}

/* icon span user and cart */
/* .top-sm-icons a:first-child a {
  height: 20px;
  width: 20px;
  background-color: var(--theme-color-main);
  color: #fff;
  border-radius: 50px;
  padding: 20px;
} */

/* .cart-icon a {
  width: 30px;
  height: 30px;
  background-color: var(--theme-color-main);
  color: #fff;
  border-radius: 50px;
} */

.cart-icon {
  display: flex;
  gap: 10px;
  align-items: center;
}

.cartt-icon {
  height: 20px;
  color: #fff !important;
}

.cart-icon span {
  border-left: 1px solid #fff !important;
  padding-left: 10px;
  color: #fff !important;
  margin-left: 5px !important;
}

.icon-user {
  color: #fff !important;
}

/* 
.cart-icon span {
  font-size: .8rem;
  background: var(--theme-color-main);
  color: #fff;
  border-radius: 5px;
  padding: 4px 8px;
  margin-left: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
 */

.user-dropdown svg {
  font-size: 1.2rem;
}

.dropdown-toggle::after {
  border: none !important;
  content: "\f107" !important;
  font-family: FontAwesome;
  vertical-align: 0 !important;
  font-weight: bold;
}

.dropdown-toggle:last-child::after:last-child {
  display: none !important;
}

.logo-image img {
  height: 80px;
}

/*  */
.dropdown-menu .dropdown-item {
  background-color: #fff !important;
}

@media (min-width: 992px) {
  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
    /* remove the gap so it doesn't close */
  }

  .offcanvas-body .nav-link {
    padding: 10px 20px !important;
  }
}

/* .box.dropdown-toggle .dropdown-menu::after {
  display: none;
} */

.user-dropdown.user-icon {
  background: var(--theme-color-main) !important;
  border-radius: 50% !important;
  color: #fff !important;
  height: 35px;
  width: 35px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.user-dropdown svg {
  font-size: 0.8rem;
}

.icon-box .cart-icon {
  background: var(--theme-color-main);
  border-radius: 50px;
  padding: 6px 15px;
  /* margin: 10px; */
  margin-left: 10px;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.navbar-toggler:focus-visible {
  outline: none !important;
}

@media (min-width: 992px) {
  .dropdown:hover {
    display: block;
  }
}

@media (max-width: 992px) {

  /* 
  .navbar-expand-lg .navbar-nav .nav-link {

    margin-right: 15px;
  } */
  .offcanvas.offcanvas-start {
    z-index: 99999 !important;
  }

  .offcanvas-body .contact-links {
    align-items: center;
    gap: 10px;
  }

  .offcanvas-body .contact-links a {
    font-size: 0.8rem;
    /* margin-left: 10px; */
  }

  .contact-links svg {
    color: var(--theme-color-main) !important;
    font-size: 1.7rem !important;
  }

  .linkk a {
    color: var(--theme-color-main) !important;
    font-size: 1.7rem !important;
  }
}

@media (max-width: 768px) {
  .nav-container {
    position: relative;
    border-bottom: 1px solid #dee2e6;
    width: 100%;
    /* position: absolute; */
    top: 0px;
    left: 0;
    z-index: 999;
    background: transparent;
  }

  .offcanvas.offcanvas-start {
    /* width: 90% !important; */
  }

  .nav-sm-icon a {
    margin-right: 5px;
    font-size: 1.5rem;
  }

  .offcanvas-body .nav-link:nth-child(1) {
    /* padding: 10px 25px !important; */
  }

  /* .content a {
    font-size: 1rem !important;
    background: var(--theme-color-main);
    color: #fff !important;
    padding: 10px 15px !important;
    text-align: center;
    border-radius: 50px;
  } */

  .box .dropdown-menu {
    border: none !important;
  }

  .linkk a {
    /* background: var(--theme-color-main) !important; */
    color: var(--theme-color-main) !important;
    font-size: 1.7rem;
  }

  .linkk {
    color: var(--theme-color-main) !important;
    font-size: 1.5rem;
  }

  .navbar-nav .dropdown-menu {
    border: none;
    padding-top: 0;
    margin-top: 0;
  }

  .offcanvas-body .nav-link:nth-child(1) {
    padding-left: 0 !important;
  }
}