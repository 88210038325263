
  
  .thankyou-section{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .thankyou-section .service-card p {
    display: flex;
    text-align: center;
    justify-content: center;
    text-align: justify;
  }
  .services-swiper .swiper-slide{
    height: auto !important;
  }
  .services-swiper .swiper-slide > div{
    height: 100%;
  }
  .services-swiper .service-card{
    flex-direction: column;
      display: flex;
      /* align-content: space-between; */
      /* flex-wrap: wrap; */
      justify-content: space-around;
  }
  