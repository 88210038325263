.product-card-main {
  width: 100%;
  text-align: left !important;
  align-items: flex-start !important;
  line-height: 1.7;
  font-size: 1.4rem;
  display: inline-block;
  text-transform: capitalize;
}

.product-card-main .r-img {
  margin-bottom: 10px;
}