.chooseus-image img {
    /* height: 550px; */
    border-radius: 10px;
}


@media (max-width:1500px) and (min-width:1200px) {

    .chooseus-image,
    .chooseus-image img {
        height: 100%;
    }


}