.service-page .page-title-area {
  background: url("../img/banners/Services-Banner.jpg");
  background-position: bottom;
}

.inner-section .nav-container {
  position: relative !important;
}

.page-title-area {
  position: relative !important;
  z-index: 1;
  background-image: url("../img/banners/aboutus-banner.jpg");
  background-position: center center;
  background-size: cover !important;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  height: 300px;
}

.page-title-area::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  /* background-color: #000; */
  left: 0;
  top: 0;
  /* opacity: 0.5; */
  z-index: -1;
}

.page-title-content {
  text-align: center;
  margin-top: 30px;
  display: none;
}

/* 
.page-title-content h2 {
    color: var(--white-color);
    font-size: 42px;
}
.page-title-content h3{
    color: var(--white-color);
    margin-bottom: 2rem;

} */

.page-title-content ul {
  padding-left: 0;
  list-style-type: none;
  /* margin-bottom: 50px; */
}

.page-title-content ul li {
  color: #ffffff;
  display: inline-block;
  position: relative;
  font-weight: 400;
  font-size: 1.3rem;
  margin-left: 10px;
  margin-right: 10px;
}

.page-title-content ul li a {
  display: inline-block;
  color: #ffffff !important;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.page-title-content ul li::before {
  content: "|";
  position: absolute;
  right: -15px;
  top: 0;
}

.page-title-content ul li:last-child::before {
  display: none;
}

.service-inner-card h2 {
  margin-bottom: 1rem;
}

.service-inner-card p {
  text-align: justify;
}

.faq-main .tab-content {
  margin-top: 1rem;
}

.faq-main .nav-tabs {
  border: none !important;
}

.faq-main .nav-tabs .nav-link {
  color: var(--theme-color-main) !important;
  border: none !important;
  border-radius: 0.375rem !important;
  padding: 10px 30px;
  border-radius: 50px !important;
  transition: .5s;
}

.faq-main .nav-tabs .nav-link:hover {
  border: none !important;
}

.faq-main .nav-tabs .nav-link.active,
.faq-main .nav-tabs .nav-item.show .nav-link {
  background: var(--theme-color-main) !important;
  color: #fff !important;
}

.faq-main .accordion-item {
  margin-bottom: 1rem !important;
  border: 1px solid var(--theme-color-main) !important;
  border-radius: .375rem !important;
}

.faq-main .accordion-item .accordion-button {
  border-radius: .375rem !important;

}

.faq-main .accordion-button:focus {
  box-shadow: none !important;
}

.faq-main .accordion-button::after {
  filter: grayscale(100);
}

.faq-main .accordion-body {
  font-size: .9rem;
}

.faq-main .accordion-button:not(.collapsed) {
  background: transparent !important;
  color: #000;
  box-shadow: none !important;
  border-bottom: 1px solid var(--theme-color-main) !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

@media (max-width:568px) {
  .faq-main .nav-item {
    margin-top: 1rem;
  }
}


.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 0;
}

/* title */

.title-container h4 {
  position: relative;
  padding: 0;
  color: var(--theme-color-main);
  line-height: 1;
  letter-spacing: 0.3px;
  font-size: 34px;
  font-weight: 700;
  text-align: center;
  text-transform: none;
  margin-bottom: 30px;
}

.title-container h4::before {
  content: '';
  width: 60px;
  height: 3px;
  background: var(--theme-color-main);
  position: absolute;
  left: 0px;
  bottom: -10px;
  right: 0;
  margin: 0 auto;
  border-radius: 5px;

}

.title-container h4 span {
  font-weight: 700;
  padding-bottom: 5px;
  color: #2f2f2f;
}

/* table */
.table-container {
  border: 1px solid var(--theme-color-main)2b;
  border-radius: 11px;
  width: 637px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

/* table-border */
tbody tr:not(:last-child) {
  border-bottom: 1px solid #e8e7e7;
}

/* tbody tr td:not(:last-child) {
  border-left: 1px solid #e8e7e7;
} */
/* table-radious */
/* table tr:first-child th:first-child {
  border-top-right-radius: 8px;
} */
/* table tr:first-child th:last-child {
  border-top-left-radius: 8px;
} */
/* table tr:last-child td:first-child {
  border-bottom-right-radius: 8px;
} */
/* table tr:last-child td:last-child {
  border-bottom-left-radius: 8px;
} */
/* style tr, td, th */
tr th {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background-color: var(--theme-color-main);
}

tr td {
  color: #606060;
  /* font-size: 12px; */
  font-size: 14px;
  font-weight: 400;
}

@media (max-width:380px) {
  tr td {
    color: #606060;
    font-size: 11px !important;
    font-weight: 400;
  }
}

th,
td {
  padding: 16px;
  text-align: center;
}

/* even and odd color */
/* tbody tr:nth-child(odd) {
  background-color: #fff;
}
tbody tr:nth-child(even) {
  background-color: #f8f8f8;
} */
table a {
  cursor: pointer !important;
  color: #fff !important;
  border: 3px solid var(--theme-color-main);
  background-image: linear-gradient(30deg, var(--theme-color-main) 50%, transparent 50%);
  background-size: 600px;
  background-repeat: no-repeat;
  background-position: 0%;
  transition: background 300ms ease-in-out;
  border-radius: 50px;
  padding: 5px 8px;
}

/* 
table a:hover {
  background-color: transparent;
  background-position: 150%;
  color: var(--theme-color-main) !important;
} */

.table a:hover {
  background-color: var(--theme-color-main);
  color: #fff;
}

.book-now a {
  background-color: var(--theme-color-main);
  color: #fff;
}

/* media query */
@media only screen and (max-width: 600px) {
  .table-container {
    width: 303px;
    overflow-x: auto;
  }
}

.down {
  display: inline-block;
}

.test5 {
  border: 1px solid var(--theme-color-main)2b;
  border-radius: 11px;
}

@media (max-width:410px) {
  .service-price-table table {
    width: 100% !important;
  }

  .down {
    display: flex;
  }
}

/* @media screen and (max-width:425px) and (max-width:770px){
  
  .down{
    display: flex;
  }
} */


@media (min-width:400px) {
  .space-price-value {
    margin-right: 10px !important;
  }
}

@media (max-width:400px) {
  .space-price-value {
    margin-right: 0px !important;
  }
}

.scroll-down-price-btn {
  cursor: pointer !important;
  cursor: pointer !important;
  color: #fff !important;
  border: 3px solid var(--theme-color-main);
  background-image: linear-gradient(30deg, var(--theme-color-main) 50%, transparent 50%);
  background-size: 850px !important;
  background-repeat: no-repeat;
  background-position: 0%;
  transition: background 300ms ease-in-out;
  border-radius: 50px;
}

.scroll-down-price-btn:hover {
  background: white !important;
  background-image: none !important;
}

/* 
  .service-inner-card li {
    margin-bottom: 3px;
  }

  .service-inner-card li h5 {
    display: inline-block;
    margin-bottom: 10px;
  }

  .list-group-item h5 {
    margin-bottom: 5px;
    margin-top: 1rem;
  } */

/* 
.img-radius {
  border-radius: 10px;
} */
.service-parallex {
  height: 80vh;
  background: url(../img/corporatephotos/parallex.png);
  background-attachment: fixed;
  border-radius: 10px;
}

.astro-skelton :where(.css-dev-only-do-not-override-1hpnbz2).ant-skeleton.ant-skeleton-element .ant-skeleton-image {
  height: 400px !important;
}