.blogsection .service-card p {
  display: flex;
  text-align: left;
  justify-content: left;
  text-align: justify;
}

,
p.awesome_line {
  color: #818181;
  font-size: 16px;
  line-height: 28px;
  text-align: left;
}

.book-page .page-title-area {
  background: url("../img/books-banneer.png");
}

.bulletpoints li {
  margin-bottom: 1rem;
}

.book-author-img {
  /* height: 80%; */
}

@media (max-width: 568px) {
  .book-page .page-title-area {
    background-position: center;
  }
}

.ariesimage {
  height: 500px;
}