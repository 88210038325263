:root {
	--mon: "Montserrat", sans-serif;
	--blue: #fff;
	--darkblue: #0a0a36;
}

.testimonial-swiper {
	height: auto;
	padding-bottom: 4rem !important;
}

.t-head h4:before {
	bottom: -20px;
}

.testimonial-swiper .swiper-pagination-bullet {
	width: 10px;
	height: 10px;
	border-radius: 30px;
	margin: 0 5px;
	transition: 0.3s;
}

.testimonial-swiper .swiper-pagination-bullet-active {
	width: 30px;
}

.single-testimonial {
	border: 7px solid #f3f3f3;
	text-align: center;
	border-radius: 45px;
	position: relative;
	z-index: 2;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.testimonial-swiper .swiper-pagination {
	/* padding-top: 3rem !important; */
}

.single-testimonial p {
	font-size: 15px;
	/* line-height: 24px; */
	padding: 50px;
	padding-bottom: 0px;
	position: relative;
	z-index: 3;
	text-align: justify;
}

.single-testimonial::before {
	content: "";
	position: absolute;
	left: -28px;
	top: -40px;
	background: url(../../img/quote.png) no-repeat var(--blue);
	background-size: 60%;
	width: 126px;
	height: 100px;
	transform: rotate(180deg);
	background-position: 34px 15px;
}

.single-testimonial::after {
	content: "";
	position: absolute;
	right: -35px;
	bottom: -34px;
	background: url(../../img/quote.png) no-repeat var(--blue);
	background-size: 60%;
	width: 126px;
	height: 100px;
	background-position: 34px 19px;
}

.round {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 1;
}

.round-1::before {
	content: "";
	position: absolute;
	left: 88px;
	top: -6px;
	width: 50px;
	height: 6px;
	border-radius: 30px;
	background: #f3f3f3;

}

.round-1::after {
	content: "";
	position: absolute;
	left: -6px;
	top: 57px;
	width: 6px;
	height: 50px;
	border-radius: 30px;
	background: #f3f3f3;

}

.round-2::before {
	content: "";
	position: absolute;
	right: 87px;
	bottom: -6px;
	width: 50px;
	height: 6px;
	background: #f3f3f3;
	border-radius: 30px;
	z-index: 1;
}

.round-2::after {
	content: "";
	position: absolute;
	right: -7px;
	bottom: 62px;
	width: 7px;
	height: 50px;
	background: #f3f3f3;
	border-radius: 30px;
	z-index: 1;
}

/* .client-video {
	padding-right: 15px;
} */
.client-info {
	position: relative;
	z-index: 3;
}

.client-info a {
	width: 40px;
	height: 40px;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
	font-size: 22px;
}

.client-info {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: left;
	padding-bottom: 50px;
}

.client-info h6 {
	font-weight: 700;
	font-size: 18px;
}



/* new  */
.testimonial-card {
	/* border: 1px solid #cebeb1; */
	background: #f8f8f8;
	padding: 20px;
	border-radius: 10px;
	position: relative;
}

.testimonial-card h3 {
	margin-bottom: 1rem !important;
}

.testimonial-card .testimonial-image {
	position: absolute;
	top: 20px;
	right: 20px;
}

.star {
	color: var(--theme-color-button);
}

.testimonial-content {
	height: 150px;
	overflow-y: auto;
}

.testimonial-content::-webkit-scrollbar {
	width: 2px;
}




.testimonial-content::-webkit-scrollbar-track {
	background: var(--theme-color-main);
}

.testimonial-content::-webkit-scrollbar-thumb {
	background: #c1c1c1;
}

@media (max-width:568px) {
	.single-testimonial p {
		padding: 20px;
		padding-top: 50px;
	}

	.client-info {
		padding-bottom: 20px;
	}

	.single-testimonial {
		height: auto;
	}
}

.text-justify {
	text-align: justify !important;
}