.read-more-button a {
  display: flex;
  width: fit-content;
}

.blog-content {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  margin-top: 0;
  margin-bottom: 20px !important;
}

.blog-section .technology-card {
  padding: 0;
  overflow: hidden;
}

.anchor-para p {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog-section .technology-card .technology-card-inner {
  padding: 0;
}

.blog-section .technology-card .technology-card-inner .service-visual {
  margin-bottom: 10px;
}

.blog-content-card {
  padding: 15px;
  padding-top: 0;
}

.blog-section .technology-card {
  text-align: left !important;
}

.blog-content-card span,
.date-content {
  /* color: var(--theme-color-orange) !important; */
  font-weight: 500;
}

.blog-section .blog-content-card svg,
.blog-detail-main .blog-btn-card svg {
  width: 25px !important;
  color: var(--theme-color-orange) !important;
  margin-right: 5px;
}

.blog-detail-main h2 {
  text-align: center;
}

.blog-detail-main p {
  text-align: center;
}

/*  */
.service-page .form-appoinment,
.blog-btn-card {
  border-radius: 10px;
  padding: 1rem;
}

.blog-btn-card ul {
  padding: 0;
}

.blog-btn-card ul li {
  list-style: none;
  align-items: flex-start;
}

.blog-btn {
  width: 100%;
  /* text-align: center; */
  padding: 10px 15px;
  display: inline-block;
  border-radius: 5px;
  /* border: 1px solid #000; */
  margin-bottom: 1rem;
  color: #000;
}

.service-btn.active {
  color: #fff;
  background-color: var(--theme-color-dark);
}

.date-content {
  color: var(--theme-color-orange) !important;
}

.blog-side-image {
  /* width: 150px; */
  width: 22% !important;
  aspect-ratio: 1/1 !important;
}

.blog-side-image img {
  height: 100%;
}

.blog-side-card {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #f5c565;
}

.blog-btn-card .blog-side-card .blog-side-content {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  width: 78%;
}

.blog-detail-main .blog-btn-card .blog-side-content a {
  font-size: 1.2rem;
  font-weight: bolder;
  color: var(--theme-color-dark);
}

.blog-detail-main .blog-btn-card .blog-side-image img {
  object-fit: cover;
  border-radius: 5px;
}

/* 
.blog-detail-main .blog-btn-card svg {
    width: 20px !important;
} */

.heading-content {
  height: 100px !important;
}

.recent-blogs {
  padding-top: 0;
  transition: 0.5s;
}

.recent-blogs.active {
  padding-top: 100px;
}

.blog-page {
  margin: 0;
}

.blog-side-content span {
  font-weight: 500;
}

.blog-btn-card span {
  font-weight: 500;
  font-size: 13px;
}

.blog-detail-page-banner .page-banner h1 {
  font-size: 2.3rem !important;
}

.blog-svg svg {
  width: 15px !important;
  color: var(--theme-color-button);
}

.recent-image img {
  border-radius: 10px !important;
  object-fit: cover;
}

.anchor-para p {
  color: #818181;
  font-size: 15px !important;
  line-height: 28px;
  text-align: left;
  line-height: normal !important;
  margin: 0;
  margin-top: 5px;
}

.anchor-para h4 {
  margin: 0;
  font-family: inherit;
}
.blog-view-all {
    background: var(--theme-color-main);
    padding: 10px 15px;
    border-radius: 50px;
    color: #fff !important;
}
.blog-view-all:hover{
    color: #fff !important;
}
@media (max-width: 568px) {
  .blog-detail-page-banner .page-banner {
    padding-top: 4rem;
    padding-bottom: 2rem;
    height: auto;
  }

  .blog-detail-page-banner .page-banner .bread-crumb li:before {
    line-height: 25px;
  }

  .blog-detail-page-banner .page-banner .bread-crumb li:last-child {
    line-height: 1.5;
  }

  .blog-detail-page-banner .page-banner .bread-crumb {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .blog-detail-page-banner .page-banner h1,
  .blog-detail-main .blog-btn-card .blog-side-content a {
    font-size: 1.1rem !important;
  }

  .recent-blogs {
    position: static !important;
  }

  .recent-blogs.active {
    padding-top: 0;
  }

  .blog-section .react-multi-carousel-list {
    padding: 0;
  }
}
