/* .content a {
    font-size: .8rem;
    background: var(--theme-color-main);
    color: #fff !important;
    padding: 8px 10px;
    text-align: center;
    border-radius: 50px;
}

.main-product-page .r-card .r-img {
    margin-bottom: 10px;
}

.main-product-page .r-card {
    width: 100%;
    text-align: left;
    line-height: 1.7;
}

.btn-box {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
} */

.product-card {
    border: 1px solid #cebeb1;
    height: 100% !important;
    border-radius: 10px;
    overflow: hidden;
}

.product-card img {
    border-bottom: 1px solid #cebeb1;
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: contain;
}

.product-card .product-content {
    padding: 8px;
}

.btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-btn-mobile:hover {
    display: flex;
    align-items: center;
    font-size: .8rem;
    background: var(--theme-color-main);
    color: #fff !important;
    /* padding: 7px 10px; */
    padding: 10px 15px;
    text-align: center;
    border-radius: 50px;
    width: fit-content;
    cursor: pointer;
}

.rupee,
.rupee>span,
.rupee>del {
    display: flex;
    align-items: center;
}

.rupee {
    margin-top: 5px;
    gap: 5px;
    justify-content: center;
}

.rupee del {
    color: #919191;
    /* padding: 5px; */

}


.product-card .ant-skeleton-image {
    aspect-ratio: 1/.8;
    height: auto !important;
}

@media (max-width:568px) {
    .products-page .r-card {
        font-size: 1.4rem;
        line-height: 3rem;
        display: inline-block;
        text-transform: capitalize;
        width: 100%;
    }

    .add-btn-mobile {
        height: auto !important;
        font-size: .8rem !important;
        padding: 10px 15px !important;
    }

    .product-card .product-content {
        padding-bottom: 10px;
    }
}