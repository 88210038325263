@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif !important;
    position: relative;
}

/* Add To CartQTY */
/*  */
.quantity {
    padding: 10px 30px !important;
    text-align: center;
    color: #fff !important;
    display: inline-block;
    /* background: var(--theme-color-main); */
    border-radius: 50px !important;
}

.minus {
    border: none;
    /* background: var(--theme-color-main); */
    /* color: #fff; */
    border-radius: 50px;
    background: transparent;
}


.plus {
    border: none;
    /* background: var(--theme-color-main); */
    /* color: #fff; */
    background: transparent;

}

.delete {
    align-items: center;
    align-self: stretch;
    background: var(--theme-color-main);
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 1.5rem;
    justify-content: center;
    padding: 0 10px;
    /* border-radius: 20px !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border-bottom-left-radius: 0 !important; */
}

.add-btn-box .quantity {
    font-size: 1rem;
    border-radius: 20px !important;
    overflow: hidden !important;

}

.add-btn-mobile {
    display: flex;
    align-items: center;
    font-size: .8rem;
    background: var(--theme-color-main);
    color: #fff !important;
    /* padding: 7px 10px; */
    padding: 10px 15px;
    text-align: center;
    border-radius: 50px;
    width: fit-content;
    cursor: pointer;
}

.bag-cart {
    display: flex;
    font-size: 15px;
    margin-right: 5px;
    margin-left: -20px;
    transform: translateY(50px) scale(0);
    transition: .5s;
    opacity: 0;
}

.add-btn-mobile:hover .bag-cart {
    opacity: 1;
    margin-left: 0;
    transform: translateY(0) scale(1);
}

.table-responsive {
    border-radius: 10px;
}

@media (max-width:568px) {
    .add-btn-mobile {
        padding: 0px 15px;
        height: 40px;
        align-items: center;
    }

    .add-btn-mobile .bag-cart {
        opacity: 1;
        margin-left: 0;
        transform: translateY(0) scale(1);
    }

    .quantity {
        padding: 0 !important;
        /* height: 40px; */
    }

    .quantity .quantity-btn {
        display: flex;
        align-items: center;
    }

    .quantity button {
        display: flex;
        align-items: center;
    }

    .quantity .quantity-delete-btn {
        margin-left: 5px;
    }
}